var config = {
    apiKey: "AIzaSyCa_pJEO_PyBBrpAJYOwEuvynMnnNeIn2Q",
    authDomain: "viibe-reviews.firebaseapp.com",
    databaseURL: "https://viibe-reviews.firebaseio.com",
    projectId: "viibe-reviews",
    storageBucket: "",
    messagingSenderId: "854984837534",
    appId: "1:854984837534:web:7f0b3e385863e73e"
  };

export default config;
