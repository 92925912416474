import React from 'react';
import { Route, Link, NavLink } from "react-router-dom"
import axios from 'axios';
import Header from './Header'
import { css } from '@emotion/core';
import { BeatLoader } from 'react-spinners';
import posed from 'react-pose';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const Box = posed.div({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.95 }
});

const Li = posed.li({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.8 }
});

export default class PersonList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      locations: [],
      locationsPerm: [],
      isLoading: true,
      sortType: '',
      sortTypeTwo: 'date',
      sortTypeLabel: 'alpha',
      linkType: 'review',
      cityArray: [],
      sortCity: ''
    }

    this.alphaClick = this.alphaClick.bind(this);
    this.dateClick = this.dateClick.bind(this);
    this.cityClick = this.cityClick.bind(this);

  }

  handleClick = () => {
    if (this.state.sortTypeTwo == 'date') {
      this.alphaClick()
    } else {
      this.dateClick()
    }
    this.setState({
      linkType: 'review'
    })
  }

  alphaClick = () => {

    let active = this.state.locationsPerm;

    function compare(a, b) {
        const orderA = a.location;
        const orderB = b.location;

        let comparison = 0;
        if (orderA > orderB) {
          comparison = 1;
        } else if (orderA < orderB) {
          comparison = -1;
        }
        return comparison;
      }

      let sorted = active.sort(compare);

      this.setState({
        locations: sorted,
        sortType: "alpha",
        sortTypeTwo: "alpha",
        sortTypeLabel: "date",
      });
  };

  beerClick = () => {

    let active = this.state.beer;

    function compare(a, b) {
        const orderA = a.location;
        const orderB = b.location;

        let comparison = 0;
        if (orderA > orderB) {
          comparison = 1;
        } else if (orderA < orderB) {
          comparison = -1;
        }
        return comparison;
      }

      let sorted = active.sort(compare);

      this.setState({
        locations: sorted,
        sortType: "beer",
        linkType: "beer",
      });
  };

  dateClick = () => {
    let active = this.state.locationsPerm;

    function compare(a, b) {
        const orderA = parseInt(a.id);
        const orderB = parseInt(b.id);

        let comparison = 0;
        if (orderA > orderB) {
          comparison = -1;
        } else if (orderA < orderB) {
          comparison = 1;
        }
        return comparison;
      }

      let sorted = active.sort(compare);

      this.setState({
        locations: sorted,
        sortType: "date",
        sortTypeTwo: "date",
        sortTypeLabel: "alpha",
      });

  };

  cityClick = () => {
    function compare(a, b) {
        const orderA = a.city;
        const orderB = b.city;

        let comparison = 0;
        if (orderA > orderB) {
          comparison = 1;
        } else if (orderA < orderB) {
          comparison = -1;
        }
        return comparison;
      }

      let sorted = this.state.locationsPerm.sort(compare);

      //Build up a list of cities for filtering by city
      let cityArray = []
      let cities = sorted.map(review => {
        let object = {
          text: `${review.city}, ${review.state}`,
          city: review.city,
          state: review.state
        }
        cityArray.push(object);
      })
      const uniqueCity = Array.from(new Set(cityArray.map(a => a.city)))
       .map(id => {
         return cityArray.find(a => a.city === id)
       })

      this.setState({ locations: sorted,
         sortType: "city",
         cityArray: uniqueCity,
         linkType: "review"
       });
  };

  changeHandler = event => {

      let active = this.state.locationsPerm;
      const name = event.target.name;
      const value = event.target.value;

      let sorted = active.filter(location => {
        return location.city == value
      })

      this.setState({locations: sorted})
  }

  componentDidMount() {
    axios.get(`https://rczod72lk6.execute-api.us-east-1.amazonaws.com/dev/locations`)
      .then(res => {
        const locations = res.data;

        const beer = locations.filter(location => location.type == 'beer');
        const food = locations.filter(location => location.type == 'restaurant');

        function compare(a, b) {
            const orderA = parseInt(a.id);
            const orderB = parseInt(b.id);

            let comparison = 0;
            if (orderA > orderB) {
              comparison = -1;
            } else if (orderA < orderB) {
              comparison = 1;
            }
            return comparison;
          }

          let sorted = food.sort(compare);


        this.setState({
          locations: sorted,
          locationsPerm: sorted,
          beer: beer,
          isLoading: false,
        });
      })
  }



  render() {
    return (
      this.state.isLoading ?
      <div>
        <Header type="one"/>
        <div className="legend">
          <div className="box a"><p id="alpha" onClick={this.handleClick}>{this.state.sortTypeLabel}</p></div>
          <div className="box b"><p id="city" onClick={this.cityClick}>City</p></div>
          <div id="beerBox" className="box c"><p onClick={this.beerClick}>Beer</p></div>
        </div>
        <div id="loadingDiv">
        <BeatLoader
          css={override}
          sizeUnit={"px"}
          size={40}
          color={'#123abc'}
          loading={this.state.loading}
        />
        </div>
      </div>
      :
      <div>
        <Header type="one"/>
        <div className="legend">
          <Box className="box a" onClick={this.handleClick}><p id="alpha">{this.state.sortTypeLabel}</p></Box>
          <Box className="box b" onClick={this.cityClick}><p id="city" >City</p></Box>
          <Box id="beerBox" className="box c" onClick={this.beerClick}><p>Beer</p></Box>
        </div>

        {this.state.sortType == 'city' ?
        <select className="citySelect" name="category" onChange={this.changeHandler}>
        <option value="select">Select a city</option>
          {this.state.cityArray.map(city =>
            <option value={city.city}>{city.text}</option>
          )}
        </select>
        :
        null}
        {this.state.sortType == 'beer' ?
        <div id="whiskyLink">
        <Link to={{
          pathname: `/whisky`
        }} id={'whiskyLinkA'}>WHISKY</Link>
        </div>
        :
        null}
        <ul className="browseUL">
          { this.state.locations.map(location =>
            <Link to={{
              pathname: `/${this.state.linkType}/${location.id}`,
              state: {
                review: location
              },
              id: location.id
            }} id={'asdfa'}>{location.location}</Link>)}
        </ul>


      </div>
    )
  }
}
