import React from 'react'
import axios from 'axios';

//Firebase
import { FirebaseAuthConsumer } from "@react-firebase/auth";

class AddScore extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      score: '',
      extras: []
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      score: event.target.value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    var initials = document.querySelector('.displayNone');
    let user = initials.value
    let extras = this.state.extras
    extras.push({user: user, score: this.state.score})

    axios.put(`https://rczod72lk6.execute-api.us-east-1.amazonaws.com/dev/locations/${this.props.id}`, {"extras" : extras})
      .then(res => {
        //Update scoreboard
        this.props.update()
        this.setState({score: ''})
        //Hide add new box
        this.props.showNew()
      })
  }

  componentDidMount() {
    axios.get(`https://rczod72lk6.execute-api.us-east-1.amazonaws.com/dev/locations/${this.props.id}`)
      .then(res => {
        this.setState({extras: res.data.extras})
      })
  }

  render() {
    return (
      <form id="addScoreForm" onSubmit={this.handleSubmit}>
        <input className="oneScoreTitle" type="number" placeholder='000000000' name="score" value={this.state.score} onChange={this.handleChange}/>
        <br />
        <FirebaseAuthConsumer>
          {({ isSignedIn, user, providerId }) => {
            if (isSignedIn == true) {
              let name = user.displayName.toLowerCase().split(' ')[0][0] + user.displayName.toLowerCase().split(' ')[1][0]
              return <input className="displayNone" type="text" name="user" value={name}/>
            }
          }}
        </FirebaseAuthConsumer>
        <input className="oneScoreTitleNew2" type="submit" value="Submit" />
      </form>
    )
  }
}

export default AddScore
