import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom'
import { css } from '@emotion/core';
import { BeatLoader } from 'react-spinners';
import Header from './Header'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class PhotoUpload extends Component {
  constructor(props){
    super(props);
    this.state = {
      success : false,
      url : "",
      isPhoto: false,
      isLoading: true,
      redirect: false,
      previewSrc: "",
      formControls: {
        caption: {
          value: ''
        },
      }
    }
  }

  //Redirect to review

  changeHandler = event => {

      const name = event.target.name;
      const value = event.target.value;
      if (name == "category" && value == "other") {
        this.setState({showOther: true})
      }
      this.setState({
        formControls: {
            ...this.state.formControls,
            [name]: {
            ...this.state.formControls[name],
            value
          }
        }
      });
  }

  handleFinish = () => {
    this.setState({redirect: true})
  }

  handleChange = (ev) => {
    var parent = this;
    let isPhoto;
    if (ev.target.value) {
      isPhoto = true;
      //FileReader for image preview
      var preview = document.querySelector('img');
      var file    = document.querySelector('#fileInput').files[0];
      var reader  = new FileReader();

      reader.addEventListener("load", function () {
        let previewSrc = reader.result;
        parent.setState({
          previewSrc: previewSrc
        })
      }, false);

      if (file) {
        reader.readAsDataURL(file);
      }
      /////
    } else {
      isPhoto = false;
    }
    this.setState({
      success: false,
      url : "",
      isPhoto: isPhoto
    });

  }
  // Perform the upload
  handleUpload = (ev) => {

    this.setState({isLoading: true})

    let file = this.uploadInput.files[0];
    // Split the filename to get the name and type
    let fileParts = this.uploadInput.files[0].name.split('.');
    let fileName = fileParts[0];
    let fileType = fileParts[1];
    console.log("Preparing the upload");
    let combinedName = this.props.match.params.id + '/' + fileName
    console.log(combinedName);
    axios.post("https://rczod72lk6.execute-api.us-east-1.amazonaws.com/dev/getSigned",{
      fileName : combinedName,
      fileType : fileType
    })
    .then(response => {
      var returnData = response.data.data.returnData;
      var signedRequest = returnData.signedRequest;
      var url = returnData.url;
      this.setState({url: url})
      console.log("Recieved a signed request " + signedRequest);

     // Put the fileType in the headers for the upload
      var options = {
        headers: {
          'Content-Type': fileType,
          'x-amz-acl': 'public-read'
        }
      };
      axios.put(signedRequest,file,options)
      .then(result => {
        console.log("Response from s3")


        const { id } = this.props.match.params

          let url= `https://rczod72lk6.execute-api.us-east-1.amazonaws.com/dev/locationsReviews/captions/${id}`

              let put = this.state.formControls.caption.value
              fetch(url, {
                  method: 'PUT',
                  body: put
                }).then(response => {
                  this.setState({
                    success: true,
                    isLoading: false,
                    isPhoto: false,
                  });
              })

      })
      .catch(error => {
        alert("ERROR " + JSON.stringify(error));
      })
    })
    .catch(error => {
      alert(JSON.stringify(error));
    })
  }

componentDidMount() {
  this.setState({isLoading: false})
}

  render() {
    if (this.state.redirect === true) {
      return <Redirect to={`/review/${this.props.match.params.id}`} />
    }
    const Success_message = () => (
      <div style={{padding:50}}>
        <h3 id="successMessage">Success</h3>
      </div>
    )
    const Gray_Select = () => (
      <p className="selectPhotoUpload gray">SELECT A PHOTO</p>
    )
    const Green_Select = () => (
      <p className="selectPhotoUpload green">SELECT A PHOTO</p>
    )
    const Upload_Button = () => (
      <button id="submitPhotoUpload" onClick={this.handleUpload}>UPLOAD</button>
    )
    const Finished_Button = () => (
      <button id="submitFinish" onClick={this.handleFinish}>FINISHED</button>
    )
    return (
      this.state.isLoading ?
      <div id="loadingDiv">
      <BeatLoader
        css={override}
        sizeUnit={"px"}
        size={40}
        color={'#123abc'}
        loading={this.state.loading}
      />
      </div>
      :
      <div className="App">
      <Header type="one"/>
        <center>
          <h1 id="photoUploadTitle">Upload a Photo</h1>

          {this.state.success ? <Success_message/> : null}
          <label class="fileContainer">
          {this.state.isPhoto ? <Green_Select /> : <Gray_Select />}
          {this.state.isPhoto ? <img id="previewImg" src={this.state.previewSrc} /> : null}
            <input id="fileInput" name="photo" onChange={this.handleChange} ref={(ref) => { this.uploadInput = ref; }} type="file"/>
          </label>
          <br/>
          {this.state.isPhoto ?
            <div id="left">
              <label className="formLabel">Caption</label>
                <input className="formInput" placeholder="(Optional)" type="text" value={this.state.formControls.caption.value} onChange={this.changeHandler} name="caption" />
                <hr className="hr" />
            </div>
             : null}
          {this.state.isPhoto ? <Upload_Button /> : null}
          {this.state.success ? <Finished_Button/> : null}
        </center>
      </div>
    );
  }
}
export default PhotoUpload;
