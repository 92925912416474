import React from 'react'
import axios from 'axios';



class HighScores extends React.Component {
  constructor() {
    super()
    this.state = {
      scores: [],
      sum: '',
      update: 0
    }

  }



  compare(a, b) {
    let a_ = parseFloat(a.score.replace(/,/g, ''));
    let b_ = parseFloat(b.score.replace(/,/g, ''));

    let comparison = 0;
      if (a_ > b_) {
        comparison = -1;
      } else if (a_ < b_) {
        comparison = 1;
      }
      return comparison;
      }

  componentDidMount() {
    axios.get(`https://rczod72lk6.execute-api.us-east-1.amazonaws.com/dev/locations/${this.props.id}`)
      .then(res => {
        let scores = []
        res.data.extras.map((score) => {
          scores.push({name: score.user, score: score.score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
        })

        //Sort scores
        scores.sort(this.compare);
        let modifiedScores = scores.slice(0,3);

        let sum = parseFloat(modifiedScores[0].score.replace(/,/g, '')) + parseFloat(modifiedScores[1].score.replace(/,/g, '')) + parseFloat(modifiedScores[2].score.replace(/,/g, ''))

        this.setState({scores: modifiedScores, sum: sum})
      })
  }

  componentDidUpdate() {
    axios.get(`https://rczod72lk6.execute-api.us-east-1.amazonaws.com/dev/locations/${this.props.id}`)
      .then(res => {
        let scores = []
        res.data.extras.map((score) => {
          scores.push({name: score.user, score: score.score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
        })

        //Sort scores
        scores.sort(this.compare);
        let modifiedScores = scores.slice(0,3);

        let sum = parseFloat(modifiedScores[0].score.replace(/,/g, '')) + parseFloat(modifiedScores[1].score.replace(/,/g, '')) + parseFloat(modifiedScores[2].score.replace(/,/g, ''))

        if (this.state.sum !== sum) {
          this.setState({scores: modifiedScores, sum: sum})
        }
        console.log("okay");
      })
  }


  render() {
    return (
      <div id="highScores">
      {this.state.scores.map((score) => {
        return (
          <div className="oneScore">
            <p className="scoreInfo">{score.name}</p>
            <p className="scoreInfo">{score.score}</p>
          </div>
        )
      })}
      <p className="oneScoreTitleNew" onClick={this.props.showNew}>+ NEW</p>

      </div>
    )
  }
}

export default HighScores

// x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
