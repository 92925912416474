import React from 'react'
import Pics from './Pics'
import Map from './Map'
import Header from './Header'
import axios from 'axios';
import { Redirect } from 'react-router-dom'
import { css } from '@emotion/core';
import { BeatLoader } from 'react-spinners';

// _Extras_
import SawadaPinball from './extras/sawadaPinball/SawadaPinball';

//Firebase
import { FirebaseAuthConsumer } from "@react-firebase/auth";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class Review extends React.Component {

  state = {
    review: [],
    data: [],
    captions: [],
    isLoading: true,
    lat: "",
    long: "",
    title: "",
    city: "",
    state: "",
    redirectToNew: false,
    redirectToNewPhoto: false,
    id: "",
    category: "",
    rating: "",
    r: "",
    g: "",
    b: "",
  }

  newReviewRedirect = () => {
    this.setState({redirectToNew: true})
  }

  newPhotoRedirect = () => {
    this.setState({redirectToNewPhoto: true})
  }

  componentDidMount() {

    document.body.classList.add('whiskyBody');


    if (this.props.location.state) {
      const { review } = this.props.location.state;
      axios.get(`https://rczod72lk6.execute-api.us-east-1.amazonaws.com/dev/getPhotoKeys/${this.props.location.state.review.id}`)
        .then(res => {
          const data = res.data;

          console.log(data);
          if(data.length > 0) {
            if(data[0].Key[data[0].Key.length - 1] == '/') {
              data.shift()
            }
          }

          let category = null;
          if (review.category) {
            category = review.category.replace( /([A-Z])/g, " $1" );
            category = category.charAt(0).toUpperCase() + category.slice(1); // capitalize the first letter - as an example.
          }


          this.setState({
            data: data,
            review: review.review,
            captions: review.captions,
            lat: review.lat,
            long: review.long,
            category: category,
            rating: review.rating,
            title: review.location,
            city: review.city,
            locality: review.locality,
            district: review.district,
            state: review.state,
            isLoading: false,
            id: this.props.location.state.review.id
          });
        })
    } else {
      const { id } = this.props.match.params

      axios.get(`https://rczod72lk6.execute-api.us-east-1.amazonaws.com/dev/locations/${id}`)
        .then(res => {
            const review = res.data;
            axios.get(`https://rczod72lk6.execute-api.us-east-1.amazonaws.com/dev/getPhotoKeys/${id}`)
              .then(res => {
                const data = res.data;

                if(data.length > 0) {
                  if(data[0].Key[data[0].Key.length - 1] == '/') {
                    data.pop()
                  }
                }

                let category = null;
                if (review.category) {
                  category = review.category.replace( /([A-Z])/g, " $1" );
                  category = category.charAt(0).toUpperCase() + category.slice(1); // capitalize the first letter - as an example.
                }

                this.setState({
                  data: data,
                  review: review.review,
                  captions: review.captions,
                  lat: review.lat,
                  long: review.long,
                  category: category,
                  rating: review.rating,
                  title: review.location,
                  locality: review.locality,
                  district: review.district,
                  city: review.city,
                  state: review.state,
                  isLoading: false,
                  id: id
                });

              })
        })
    }
  }

  render () {
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
    if (this.state.redirectToNew === true) {
      return (

        <FirebaseAuthConsumer>
          {({ isSignedIn, user, providerId }) => {
            if (isSignedIn == true) {
              let name = user.displayName.toLowerCase().split(' ')[0]
              {/*_Extras_*/}
              if (this.state.id == '234') {
                return <Redirect to={{pathname: `/newReviewJenis/${this.state.id}`,state: { user: name }}}/>
              } else if (this.state.id == '87') {
                return <Redirect to={{pathname: `/NewReviewSaltAndStraw/${this.state.id}`,state: { user: name }}}/>
              } else {
                return <Redirect to={{pathname: `/newAdd/${this.state.id}`,state: { user: name }}}/>
              }
            } else {
              return <Redirect to='/login' />
            }
          }}
        </FirebaseAuthConsumer>

      )
    } else if (this.state.redirectToNewPhoto === true) {
      return <Redirect to={`/photoUpload/${this.state.id}`} />
    }

    return (
      this.state.isLoading ?
      <div id="loadingDiv">
      <BeatLoader
        css={override}
        sizeUnit={"px"}
        size={40}
        color={'#123abc'}
        loading={this.state.loading}
      />
      </div>
      :
      <div id="outerOverallWhisky">
      <Header type="w"/>
      <div id="titleDiv">
        <h1 id="titleWhisky">{this.state.title}</h1>
        <h3 id="address">{this.state.locality ? this.state.locality + ", " : null} {this.state.district ? this.state.district + ", " : null} {this.state.city}, {this.state.state}</h3>
        {this.state.category ? <h3 id="addressLight"> {this.state.category}</h3> : null}
      </div>
      {/*_Extras_*/}
      {this.state.id == "240" ? <SawadaPinball id={this.state.id} /> : null}
      <div id="reviewDiv">
        {this.state.review.map(review =>
          <div id="textWhisky">
            {/*_Extras_*/}
            {this.state.id == "234" ? <pre><p className="flavorText">{review.flavor}</p></pre> : null}
            {this.state.id == "87" ? <pre><p className="saltAndStraw">{review.flavor}</p></pre> : null}

            <pre><p id="reviewTextWhisky">{review.review}</p></pre>
            <p id="date">
            {review.reviewer} | {monthNames[new Date(review.date).getMonth()]} {new Date(review.date).getDate()}, {new Date(review.date).getFullYear()}
            </p>
            <div id="ratingDivReview">

            {review.rating ? <p id="ratingBubbleReview" style={{background: `rgb(${review.r}, ${review.g}, ${review.b})`}}>{review.rating}</p> : null}
            </div>
          </div>
        )}
      </div>
        {(this.state.data.length > 0) ? <Pics data={this.state.data} captions={this.state.captions} /> : null}
        <div className="reviewChoices">
          <div className="boxLower a"><span className="spanWhisky" onClick={this.newReviewRedirect}>+</span><p className="reviewButtonsWhisky">Review</p></div>
          <div className="boxLower b"><span className="spanWhisky" onClick={this.newPhotoRedirect}>+</span><p className="reviewButtonsWhisky">Photos</p></div>
        </div>
        {this.state.lat ? <Map lat={this.state.lat} long={this.state.long}/> : null}
      </div>
    )
  }
}

export default Review;
