import React from 'react'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import { css } from '@emotion/core';
import { BeatLoader } from 'react-spinners';
import Header from './Header'
import Slider from 'react-input-slider';

//Firebase
import { FirebaseAuthConsumer } from "@react-firebase/auth";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class NewReview extends React.Component {



  constructor(props) {
      super(props);
      this.state = {
        formControls: {
          location: {
            value: ''
          },
          city: {
            value: ''
          },
          state: {
            value: ''
          },
          review: {
            value: ''
          },
          reviewer: {
            value: this.props.location.state.user
          },
          category: {
            value: 'fastFood'
          }
        },
        maxID: 0,
        redirectToReferrer: false,
        isLoading: false,
        showOther: false,
        x: 0
      }

      this.formSubmitHandler = this.formSubmitHandler.bind(this);
    }



  changeHandler = event => {

      const name = event.target.name;
      const value = event.target.value;
      if (name == "category" && value == "other") {
        this.setState({showOther: true})
      }
      this.setState({
        formControls: {
            ...this.state.formControls,
            [name]: {
            ...this.state.formControls[name],
            value
          }
        }
      });
  }

  formSubmitHandler = event => {

    event.preventDefault()
    this.setState({isLoading: true})

    let address = this.state.formControls["location"].value.replace(' ', '+') + ',+' + this.state.formControls["city"].value.replace(' ', '+') + "+" + this.state.formControls["state"].value.replace(' ', '+')

    let url= 'https://maps.googleapis.com/maps/api/geocode/json?address=' + address + '&key=AIzaSyCa_pJEO_PyBBrpAJYOwEuvynMnnNeIn2Q'
    axios.get(url)
      .then(res => {
        let lat;
        let long;
        let locality;
        if (res.data.results[0]) {
          lat = res.data.results[0].geometry.location.lat
          long = res.data.results[0].geometry.location.lng
          //To get neighborhood

          //Chicago
          //London
          if (this.state.formControls.city.value.trim().toLowerCase() == 'chicago' || this.state.formControls.city.value.trim().toLowerCase() == 'london')
          if (res.data.results[0].address_components.filter(e => e.types.includes('neighborhood')).length > 0) {
            let filteredHood = res.data.results[0].address_components.filter(e => e.types.includes('neighborhood'))
            locality = filteredHood[0].long_name
          }
        } else {
          //In case there is issue
          lat = "0"
          long = "0"
        }

        let url= 'https://rczod72lk6.execute-api.us-east-1.amazonaws.com/dev/locations'
        let maxID
        //Get number of highest id
        axios.get(url)
          .then(res => {
            const reviews = res.data;
            let idArray = []
            reviews.forEach(function (review) {
              idArray.push(parseInt(review.id))
            })
            maxID = idArray.reduce(function(a, b) {
                return Math.max(a, b);
            });
            maxID += 1;
            this.setState({maxID})
          }).then(res => {
            let post = {
              id: maxID.toString(),
              location: this.state.formControls.location.value.trim(),
              city: this.state.formControls.city.value.trim(),
              locality: locality,
              state: this.state.formControls.state.value.trim(),
              review: {
                review: this.state.formControls.review.value,
                reviewer: this.state.formControls.reviewer.value,
                date: Date.now(),
                rating: this.state.x,
                r: (255-(this.state.x*1.14)),
                g:(66+(this.state.x*1.48)),
                b: (66+(this.state.x*0.4))
              },
              type: 'restaurant',
              lat: lat,
              long: long,
              category: this.state.formControls.category.value,
            }
            post = JSON.stringify(post)
            fetch(url, {
                method: 'POST',
                body: post
              }).then(response => {
                let redirectToReferrer = true
                this.setState({redirectToReferrer})
              })
          })

      })

  }

  componentDidMount() {

  }

  render () {
    //Redirect after submitted
    if (this.state.redirectToReferrer === true) {
            return <Redirect to={`/review/${this.state.maxID}`} />
    }
    let backgroundColorString = `rgb(${(255-(this.state.x*1.14))}, ${(66+(this.state.x*1.48))}, ${(66+(this.state.x*0.4))})`
    const bubbleStyle = {
      background: backgroundColorString,
    };
    return (
      this.state.isLoading ?
      <div id="loadingDiv">
      <BeatLoader
        css={override}
        sizeUnit={"px"}
        size={40}
        color={'#123abc'}
        loading={this.state.loading}
      />
      </div>
      :
      <div>
      <Header type="two"/>
        <h1 id="formTitle">New Review</h1>
        <form className="form">
          <label className="formLabel">Location</label>
            <input className="formInput" type="text" name="location" value={this.state.formControls.location.value} onChange={this.changeHandler} />
            <hr className="hr" />
          <label className="formLabel">City</label>
            <input className="formInput" type="text" value={this.state.formControls.city.value} onChange={this.changeHandler} name="city" />
            <hr className="hr" />
          <label className="formLabel">State (ex: IL)</label>
            <input className="formInput" type="text" value={this.state.formControls.state.value} onChange={this.changeHandler} name="state" />
            <hr className="hr" />
          <label className="formLabel">Review</label>
            <textarea className="formTextarea" type="text" value={this.state.formControls.review.value} onChange={this.changeHandler} name="review" />
            <hr className="hr" />
          <label className="formLabel none">Reviewer</label>
            <select className="formSelect none" name="reviewer" onChange={this.changeHandler}>
              <FirebaseAuthConsumer>
                {({ isSignedIn, user, providerId }) => {
                  if (isSignedIn == true) {
                    return <option value={user.displayName.toLowerCase().split(' ')[0]}>{user.displayName.split(' ')[0]}</option>
                  } else {
                    return <Redirect to='/login' />
                  }

                }}
              </FirebaseAuthConsumer>
            </select>
          <label className="formLabel">Category</label>
            <select className="formSelect" name="category" onChange={this.changeHandler}>
              <option value='fastFood'>Fast Food</option>
              <option value='fancy'>Fancy</option>
              <option value='bbq'>BBQ</option>
              <option value='italian'>Italian</option>
              <option value='pizza'>Pizza</option>
              <option value='american'>American</option>
              <option value='asian'>Asian</option>
              <option value='vegan'>Vegan</option>
              <option value='dessert'>Dessert</option>
              <option value='bar'>Bar</option>
              <option value='mexican'>Mexican</option>
              <option value='breakfast'>Breakfast</option>
              <option value='other'>Other</option>
            </select>
            <hr className="hr" />
            <div>{this.state.showOther ? <div><label className="formLabel">Enter Other Category</label><input className="formInput" type="text" name="category" value={this.state.formControls.category.value} onChange={this.changeHandler} /><hr className="hr" /></div> : null}</div>

            <div id="ratingDiv">
              <p id="ratingBubble" style={bubbleStyle}>{this.state.x}</p>
            </div>
          <label className="formLabel">Rating</label><br />

          <div id="sliderDiv">
            <Slider
              axis="x"
              xstep={1}
              xmin={0}
              xmax={100}
              styles={{
                active: {
                  backgroundColor: backgroundColorString
                }
              }}
              x={this.state.x}
              onChange={({ x }) => this.setState({ x: parseFloat(x.toFixed(2)) })}
            />
          </div>

          <input className="newSubmit" id="submit" type="submit" value="Submit" onClick={this.formSubmitHandler} />
        </form>
      </div>
    )
  }
}

export default NewReview;
