import React from 'react';
import './App.css';

//Components
import LocationList from './LocationList.js';
import Login from './Login.js';
import Review from './Review.js';
import Beer from './Beer.js';
import Landing from './Landing.js';
import NewReview from './NewReview.js';
import NewReviewAdd from './NewReviewAdd.js';
import NewReviewBeer from './NewReviewBeer.js';
import NewReviewWhisky from './NewReviewWhisky.js';
import NewReviewAddBeer from './NewReviewAddBeer.js';
import NewReviewAddWhisky from './NewReviewAddWhisky.js';
import PhotoUpload from './PhotoUpload.js';
import ChooseTypeOfReview from './ChooseTypeOfReview.js';
import WhiskyList from './WhiskyList.js';
import Whisky from './Whisky.js';

//Extras
import NewReviewJenis from './extras/newReviewJenis/NewReviewJenis.js'
import NewReviewSaltAndStraw from './extras/newReviewSaltAndStraw/NewReviewSaltAndStraw.js'

//Router
import { BrowserRouter, Route, Link, Redirect} from "react-router-dom"

//Firebase
import * as firebase from "firebase/app";
import "firebase/auth";
import {
  FirebaseAuthProvider,
  FirebaseAuthConsumer,
  IfFirebaseAuthed,
  IfFirebaseAuthedAnd
} from "@react-firebase/auth";
import config from "../firebaseConfig";



class App extends React.Component {

  render() {

      return (
        <FirebaseAuthProvider {...config} firebase={firebase}>
          <BrowserRouter>

            <div className="App">
              <Route exact path='/' component={Landing} />
              <Route exact path='/login' component={Login} />
              <Route path='/new' component={NewReview} />
              <Route path='/newAdd/:id' component={NewReviewAdd} />
              <Route path='/newBeer/' component={NewReviewBeer} />
              <Route path='/newAddBeer/:id' component={NewReviewAddBeer} />
              <Route path='/newWhisky/' component={NewReviewWhisky} />
              <Route path='/newAddWhisky/:id' component={NewReviewAddWhisky} />
              <Route path='/chooseType' component={ChooseTypeOfReview} />

              {/*_Extras_*/}
              <Route path='/newReviewJenis/:id' component={NewReviewJenis} />
              <Route path='/newReviewSaltAndStraw/:id' component={NewReviewSaltAndStraw} />

              <Route path='/browse' component={LocationList} />
              <Route exact path='/whisky' component={WhiskyList} />
              <Route path='/whisky/:id' component={Whisky} />
              <Route path='/review/:id' component={Review} />
              <Route path='/beer/:id' component={Beer} />
              <Route path='/photoUpload/:id' component={PhotoUpload} />

            </div>


          </BrowserRouter>
        </FirebaseAuthProvider>
      );
  }
}

export default App;
