import React from 'react'
import {Link, Redirect} from "react-router-dom"
import home from '../photos/home.png';
import plus from '../photos/plus.png';

//Firebase
import { FirebaseAuthConsumer } from "@react-firebase/auth";

class Landing extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      redirectHome: false,
      redirectNew: false
    }
  }

  redirectHome = () => {
    this.setState({redirectHome: true})
  }

  redirectNew = () => {
    this.setState({redirectNew: true})
  }


  render () {
    if (this.state.redirectHome === true) {
      return <Redirect to={`/`} />
    } else if (this.state.redirectNew === true) {
      return (
        <FirebaseAuthConsumer>
          {({ isSignedIn, user, providerId }) => {
            if (isSignedIn == true) {
              let name = user.displayName.toLowerCase().split(' ')[0]
              return <Redirect to={{pathname: `/chooseType`,state: { user: name }}}/>
            } else {
              return <Redirect to='/login' />
            }
          }}
        </FirebaseAuthConsumer>
      )
    }

    let Head
    if (this.props.type == "one") {
      Head = () => (
        <div id="headerDiv">
          <img id="homeIcon" src={home} alt="Home" onClick={this.redirectHome} />
          <img id="plusIcon" src={plus} alt="Plus" onClick={this.redirectNew}/>
        </div>
      )
    } else if (this.props.type == "w") {
      Head = () => (
        <div id="headerDivWhiskey">
          <img id="homeIcon" src={home} alt="Home" onClick={this.redirectHome} />
        </div>
      )
    } else if (this.props.type == "two") {
      Head = () => (
        <div id="headerDiv">
          <img id="homeIcon" src={home} alt="Home" onClick={this.redirectHome} />
        </div>
      )
    } else if (this.props.type == "zero") {
      Head = () => (
        <div id="headerDiv">
        </div>
      )
    } else if (this.props.type == "three") {
      Head = () => (
        <div id="headerDiv">
          <img id="plusIcon" src={plus} alt="Plus" onClick={this.redirectNew}/>
        </div>
      )
    }

    return (
      <Head />
    )
  }
}

export default Landing;
