import React, { Component, PropTypes } from 'react'
import MapboxGl from 'mapbox-gl/dist/mapbox-gl.js'


class Map extends Component {

  constructor(props) {
    super(props);
    this.state = { lat: '', lng: ''};

    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  handleFormSubmit(newState) {
    console.log(newState)

    this.setState(newState, function() {
          console.log(this.state)
    })

  }

  componentDidUpdate() {
    MapboxGl.accessToken = 'pk.eyJ1IjoiYnJpYW5wb3dlcnMiLCJhIjoiY2pxaDFnZTR1MGY3OTQybzBtcXc2N3h0YiJ9.5HgCBO-2VSizI37U5JBK2A'

    let center = [parseFloat(this.state.lat), parseFloat(this.state.lng)]

    var map = new MapboxGl.Map({
      container: this.container,
      style: 'mapbox://styles/brianpowers/cjv5z4e7b9s301frznhyzhqs0',
      center: center,
      height: '200px',
      width: '50px',
      zoom: 15.5,
      pitch: 45,
      bearing: -17.6,
    })




    // The 'building' layer in the mapbox-streets vector source contains building-height
        // data from OpenStreetMap.
    map.on('load', function() {
      // Insert the layer beneath any symbol layer.
      var layers = map.getStyle().layers;

      var labelLayerId;
      for (var i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
          labelLayerId = layers[i].id;
          break;
        }
      }

      var size = 100;

var pulsingDot = {
width: size,
height: size,
data: new Uint8Array(size * size * 4),

onAdd: function() {
var canvas = document.createElement('canvas');
canvas.width = this.width;
canvas.height = this.height;
this.context = canvas.getContext('2d');
},

render: function() {
var duration = 3000;
var t = (performance.now() % duration) / duration;

var radius = size / 2 * 0.3;
var outerRadius = size / 2 * 0.7 * t + radius;
var context = this.context;

// draw outer circle
context.clearRect(0, 0, this.width, this.height);
context.beginPath();
context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
context.fillStyle = 'rgba(0, 0, 0,' + (1 - t) + ')';
context.fill();

// draw inner circle
context.beginPath();
context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
context.fillStyle = 'rgba(223, 232, 253, 1)';
context.strokeStyle = 'white';
context.lineWidth = 2 + 4 * (1 - t);
context.fill();
context.stroke();

// update this image's data with data from the canvas
this.data = context.getImageData(0, 0, this.width, this.height).data;

// keep the map repainting
map.triggerRepaint();

// return `true` to let the map know that the image was updated
return true;
}
};

      map.addLayer({
        'id': '3d-buildings',
        'source': 'composite',
        'source-layer': 'building',
        'filter': ['==', 'extrude', 'true'],
        'type': 'fill-extrusion',
        'minzoom': 15,
        'paint': {
          'fill-extrusion-color': '#aaa',

          // use an 'interpolate' expression to add a smooth transition effect to the
          // buildings as the user zooms in
          'fill-extrusion-height': [
            "interpolate", ["linear"], ["zoom"],
            15, 0,
            15.05, ["get", "height"]
          ],
            'fill-extrusion-base': [
            "interpolate", ["linear"], ["zoom"],
            15, 0,
            15.05, ["get", "min_height"]
          ],
          'fill-extrusion-opacity': .6
        }
      }, labelLayerId);


      map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });

map.addLayer({
"id": "points",
"type": "symbol",
"source": {
"type": "geojson",
"data": {
"type": "FeatureCollection",
"features": [{
"type": "Feature",
"geometry": {
"type": "Point",
"coordinates": center
}
}]
}
},
"layout": {
"icon-image": "pulsing-dot"
}
});

map.setLight({color: "#fff", intensity: 1, position: [1.15, 135, 45]});

    });
  }

  componentDidMount() {
      this.setState({lat: this.props.long, lng: this.props.lat})
  }

  render() {
    return (
      <div id="outer">
        <div className='Map' ref={(x) => { this.container = x }}>

        </div>
      </div>
    )
  }
}

export default Map
