import React from 'react'
import {Link, Redirect} from "react-router-dom"
import Header from './Header'
import berries from '../photos/berries.jpeg';

//Firebase
import { FirebaseAuthConsumer } from "@react-firebase/auth";

class ChooseTypeOfReview extends React.Component {

  render () {

    return (

        <div>
          <Header type="two"/>
          <ul id="homeUL">
            <FirebaseAuthConsumer>
              {({ isSignedIn, user, providerId }) => {
                if (isSignedIn == true) {
                  let name = user.displayName.toLowerCase().split(' ')[0]
                  return(
                    <div>
                      <li className="homeButtons homeButtonsNew"><Link to={{pathname: `/new`,state: { user: name }}}>Food</Link></li>
                      <li className="homeButtons homeButtonsNew"><Link to={{pathname: `/newBeer`,state: { user: name }}}>Beer</Link></li>
                      <li className="homeButtons homeButtonsNew"><Link to={{pathname: `/newWhisky`,state: { user: name }}}>Whisky</Link></li>
                    </div>
                  )
                } else {
                  return <Redirect to='/login' />
                }
                }}
            </FirebaseAuthConsumer>
          </ul>
        </div>

    )
  }
}

export default ChooseTypeOfReview;
