import React from 'react';
import { Route, Link, NavLink } from "react-router-dom"
import axios from 'axios';
import Header from './Header'
import { css } from '@emotion/core';
import { BeatLoader } from 'react-spinners';
import posed from 'react-pose';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const Box = posed.div({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.95 }
});

const Li = posed.li({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.8 }
});

export default class PersonList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      locations: [],
      locationsPerm: [],
      isLoading: true,
      sortType: '',
      sortTypeTwo: 'date',
      sortTypeLabel: 'alpha',
      linkType: 'review',
      cityArray: [],
      sortCity: ''
    }

  }

  handleClick = () => {
    if (this.state.sortTypeTwo == 'date') {
      this.alphaClick()
    } else {
      this.dateClick()
    }
    this.setState({
      linkType: 'review'
    })
  }


  changeHandler = event => {

      let active = this.state.locationsPerm;
      const name = event.target.name;
      const value = event.target.value;

      let sorted = active.filter(location => {
        return location.city == value
      })

      this.setState({locations: sorted})
  }

  componentDidMount() {
    axios.get(`https://rczod72lk6.execute-api.us-east-1.amazonaws.com/dev/locations`)
      .then(res => {
        const locations = res.data;

        const whisky = locations.filter(location => location.type == 'whisky');

        function compare(a, b) {
            const orderA = parseInt(a.id);
            const orderB = parseInt(b.id);

            let comparison = 0;
            if (orderA > orderB) {
              comparison = -1;
            } else if (orderA < orderB) {
              comparison = 1;
            }
            return comparison;
          }

          let sorted = whisky.sort(compare);


        this.setState({
          locations: sorted,
          locationsPerm: sorted,
          whisky: whisky,
          isLoading: false,
        });
      })
  }



  render() {
    return (
      this.state.isLoading ?
      <div>
        <Header type="one"/>
        <div id="loadingDiv">
        <BeatLoader
          css={override}
          sizeUnit={"px"}
          size={40}
          color={'#123abc'}
          loading={this.state.loading}
        />
        </div>
      </div>
      :
      <div>
        <Header type="one"/>
        {this.state.sortType == 'city' ?
        <select className="citySelect" name="category" onChange={this.changeHandler}>
        <option value="select">Select a city</option>
          {this.state.cityArray.map(city =>
            <option value={city.city}>{city.text}</option>
          )}
        </select>
        :
        null}
        <ul className="browseUL">
          { this.state.whisky.map(location =>
            <Link to={{
              pathname: `/whisky/${location.id}`,
              state: {
                review: location
              },
              id: location.id
            }} id={'asdfa'}>{location.location}</Link>)}
        </ul>


      </div>
    )
  }
}
