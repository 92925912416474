import React from 'react'
import pinball from '../../../photos/extras/pinball.png'

class Title extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      message: 'PINBALL',
      version: 0
    }
  }

  changeMessage() {
    let version = this.state.version + 1;
    let message;

    switch(version%2) {
      case 0:
        message = 'PINBALL'
        break;
      case 1:
        message = 'HIGH SCORES'
        break;
      default:
        // code block
    }

    this.setState({
      version: version%2,
      message: message
    })
  }

  componentDidMount() {
    setInterval(() => this.changeMessage(), 1000);
  }

  render() {
    return (
      <div className="oneScoreTitle" onClick={this.props.showScores}>
        <p className="scoreInfo">{this.state.message}</p>
      </div>
    )
  }
}

export default Title
