import React, { Component } from 'react'
import axios from 'axios';

import ImageGallery from 'react-image-gallery';



class Pics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      photoID: null
    }
  }

  componentDidMount() {
    this.setState({ data: this.props.data});
  }

  componentDidUpdate(prevProps, prevState) {

  }

  render() {
    let rand = Math.random();

    const images = []
    this.state.data.map(function(key) {

        let originalString = 'https://s3-us-west-1.amazonaws.com/brianpow.us.foodreviewphotos/' + key.Key;
        let thumbnailString = 'https://s3-us-west-1.amazonaws.com/brianpow.us.foodreviewphotos/' + key.Key;
        let uploadDate = new Date(key.LastModified)

        let instance = {
          original: originalString,
          thumbnail: thumbnailString,
          date: uploadDate,
        }

      images.push(instance)

    })

    //SORTS photo array to order by date added
    function compare(a, b) {
      // Use toUpperCase() to ignore character casing
      const dateA = a.date
      const dateB = b.date

      let comparison = 0;
      if (dateA > dateB) {
        comparison = 1;
      } else if (dateA < dateB) {
        comparison = -1;
      }
      return comparison;
    }

    let newImages = images.sort(compare);
    console.log(newImages);

    //Adds captions if they exist

    for (let i = 0; i < images.length; i++) {
      newImages[i].description = this.props.captions[i]
    }

    return (
      <ImageGallery items={newImages} showThumbnails={false} showPlayButton={false} />
    )
  }
}

export default Pics
