import React from 'react'
import Title from './Title'
import HighScores from './HighScores'
import AddScore from './AddScore'

import './SawadaPinball.css';

class SawadaPinball extends React.Component {
  constructor() {
    super()
    this.state = {
      showScores: false,
      showNew: false,
      update: 0
    }

    this.showScores = this.showScores.bind(this);
    this.showNew = this.showNew.bind(this);
    this.updateComponent = this.updateComponent.bind(this);
  }

  updateComponent() {
    let update = this.state.update + 1
    this.setState({ update: update });
  }

  showScores() {
    if (this.state.showScores == true) {
      this.setState({showScores: false})
    } else {
      this.setState({showScores: true})
    }
  }

  showNew() {
    if (this.state.showNew == true) {
      this.setState({showNew: false})
    } else {
      this.setState({showNew: true})
    }
  }

  render() {
    return (
      <div>
        <Title showScores={this.showScores}/>
        {this.state.showScores == true ? <HighScores showNew={this.showNew} id={this.props.id} /> : null}
        {(this.state.showNew == true && this.state.showScores == true) ? <AddScore showNew={this.showNew} id={this.props.id} update={this.updateComponent} /> : null}
      </div>
    )
  }
}

export default SawadaPinball
