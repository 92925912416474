import React from 'react'
import {Link, Redirect} from "react-router-dom"
import Header from './Header'
import berries from '../photos/berries.jpeg';

//Firebase
import { FirebaseAuthConsumer } from "@react-firebase/auth";

class Landing extends React.Component {

  componentDidMount () {
    document.body.classList.remove('whiskyBody');
  }

  render () {

    return (

        <div>
          <Header type="three"/>
          <FirebaseAuthConsumer>
            {({ isSignedIn, user, providerId }) => {
              if (isSignedIn == true) {
                return (
                  null
                );
              } else {
                return <Redirect to='/login' />
              }

            }}
          </FirebaseAuthConsumer>
          <ul id="homeUL">
            <FirebaseAuthConsumer>
              {({ isSignedIn, user, providerId }) => {
                if (isSignedIn == true) {
                  let name = user.displayName.toLowerCase().split(' ')[0]
                  return <li className="homeButtons homeButtonsNew"><Link to={{pathname: `/chooseType`,state: { user: name }}}>New Review</Link></li>
                } else {
                  return <Redirect to='/login' />
                }
                }}
            </FirebaseAuthConsumer>
            <li className="homeButtons homeButtonsBrowse"><Link to='/browse'>Browse</Link></li>
          </ul>
          <img id="berries" src={berries} alt="Strawberries" />
        </div>

    )
  }
}

export default Landing;
