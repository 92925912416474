import React from 'react'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import { css } from '@emotion/core';
import { BeatLoader } from 'react-spinners';
import Header from '../../Header'
import Slider from 'react-input-slider';

//Firebase
import { FirebaseAuthConsumer } from "@react-firebase/auth";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class NewReviewSaltAndStraw extends React.Component {


  constructor(props) {
      super(props);
      this.state = {
        formControls: {
          review: {
            value: ''
          },
          reviewer: {
            value: this.props.location.state.user
          },
          flavor: {
            value: ''
          },
        },
        maxID: 0,
        redirectToReferrer: false,
        isLoading: false,
        x: 0
      }

      this.formSubmitHandler = this.formSubmitHandler.bind(this);
    }



  changeHandler = event => {

      const name = event.target.name;
      const value = event.target.value;
      this.setState({
        formControls: {
            ...this.state.formControls,
            [name]: {
            ...this.state.formControls[name],
            value
          }
        }
      });

  }

  formSubmitHandler = event => {

    event.preventDefault()
    this.setState({isLoading: true})

    const { id } = this.props.match.params

      let url= `https://rczod72lk6.execute-api.us-east-1.amazonaws.com/dev/locationsReviews/${id}`

          let put = {
              review: this.state.formControls.review.value,
              reviewer: this.state.formControls.reviewer.value,
              flavor: this.state.formControls.flavor.value,
              date: Date.now(),
              rating: this.state.x,
              r: (255-(this.state.x*1.14)),
              g:(66+(this.state.x*1.48)),
              b: (66+(this.state.x*0.4))
          }
          put = JSON.stringify(put)
          fetch(url, {
              method: 'PUT',
              body: put
            }).then(response => {
              let redirectToReferrer = true
              this.setState({redirectToReferrer})
          })
  }



  componentDidMount() {

  }

  render () {
    if (this.state.redirectToReferrer === true) {
            return <Redirect to={`/review/${this.props.match.params.id}`} />
    }
    let backgroundColorString = `rgb(${(255-(this.state.x*1.14))}, ${(66+(this.state.x*1.48))}, ${(66+(this.state.x*0.4))})`
    const bubbleStyle = {
      background: backgroundColorString,
    };
    return (
      this.state.isLoading ?
      <div id="loadingDiv">
      <BeatLoader
        css={override}
        sizeUnit={"px"}
        size={40}
        color={'#123abc'}
        loading={this.state.loading}
      />
      </div>
      :
      <div>
      <FirebaseAuthConsumer>
        {({ isSignedIn, user, providerId }) => {
          if (isSignedIn == true) {
          } else {
            return <Redirect to='/login' />
          }

        }}
      </FirebaseAuthConsumer>
        <Header type="two"/>
        <h1 id="formTitle">New Review</h1>
        <form className="form">
          <label className="formLabel">Flavor</label>
            <input className="formInput" type="text" value={this.state.formControls.flavor.value} onChange={this.changeHandler} name="flavor" />
            <hr className="hr" />
          <label className="formLabel">Review</label>
            <textarea className="formTextarea" type="text" value={this.state.formControls.review.value} onChange={this.changeHandler} name="review" />
            <hr className="hr" />
          <label className="formLabel none">Reviewer</label>
            <select className="formSelect none" name="reviewer" onChange={this.changeHandler}>
            <FirebaseAuthConsumer>
              {({ isSignedIn, user, providerId }) => {
                if (isSignedIn == true) {
                  return <option value={user.displayName.toLowerCase().split(' ')[0]}>{user.displayName.split(' ')[0]}</option>
                } else {
                  return <Redirect to='/login' />
                }

              }}
            </FirebaseAuthConsumer>
            </select>
            <div id="ratingDiv">
              <p id="ratingBubble" style={bubbleStyle}>{this.state.x}</p>
            </div>
          <label className="formLabel">Rating</label><br />
          <div id="sliderDiv">
            <Slider
              axis="x"
              xstep={1}
              xmin={0}
              xmax={100}
              styles={{
                active: {
                  backgroundColor: backgroundColorString
                }
              }}
              x={this.state.x}
              onChange={({ x }) => this.setState({ x: parseFloat(x.toFixed(2)) })}
            />
          </div>

          <input className="newSubmit" id="submit" type="submit" value="Submit" onClick={this.formSubmitHandler} />
        </form>
      </div>
    )
  }
}

export default NewReviewSaltAndStraw;
