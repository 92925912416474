import React from 'react';
import './App.css';

import berries from '../photos/berries.jpeg';

//component
import Header from './Header'

//Loading bar
import { css } from '@emotion/core';
import { BeatLoader } from 'react-spinners';

//Router
import { Redirect} from "react-router-dom"

//Firebase
import * as firebase from "firebase/app";
import "firebase/auth";
import {
  FirebaseAuthProvider,
  FirebaseAuthConsumer,
  IfFirebaseAuthed,
  IfFirebaseAuthedAnd
} from "@react-firebase/auth";
import config from "../firebaseConfig";

class Login extends React.Component {
  constructor(props) {
    super(props);
  }


  render () {
    return (
      <div>
        <Header type="zero"/>
        <FirebaseAuthConsumer>
          {({ isSignedIn, user, providerId }) => {
            if (isSignedIn == false) {
              return (
                null
              );
            } else {
              return <Redirect to='/' />
            }

          }}
        </FirebaseAuthConsumer>

        <button
          onClick={() => {
            const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
            firebase.auth().signInWithPopup(facebookAuthProvider);
          }} className="loginButtons"
        >
          Sign In with Facebook
        </button>
        <img id="loginImg" src={berries} alt="Strawberries" />
      </div>
    )
  }
}

export default Login;
